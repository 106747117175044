import React, {useState} from "react";
import styles from './Card.module.scss';

let FaqCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  let cardClasses = [styles.card, styles.faqCard];

  if (expanded) {
    cardClasses = [...cardClasses, styles.expanded]
  }

  return (
    <div className={cardClasses.join(" ")}>
        {
          props.title ? (
            <div className={styles.cardTitle} onClick={() => {setExpanded(!expanded)}}>
              <h3>{props.title}</h3>
              <span className={styles.expandArrow}>
                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.78947 7.78781L0.202105 1.92106C-0.0673684 1.63811 -0.0673684 1.17938 0.202105 0.896463L0.853781 0.212204C1.1228 -0.0702607 1.55879 -0.0708043 1.82843 0.210996L6.27738 4.8605L10.7263 0.210996C10.9959 -0.0708043 11.4319 -0.0702607 11.701 0.212204L12.3526 0.896463C12.6221 1.17941 12.6221 1.63814 12.3526 1.92106L6.7653 7.78781C6.49582 8.07073 6.05894 8.07073 5.78947 7.78781Z" fill="#333333"/>
                </svg>
              </span>
            </div>
          ) : ""
        }
        <div className={styles.faqContent}>
          {props.content}
        </div>
    </div>
  )
}

export default FaqCard;