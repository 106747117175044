import React, {Component} from 'react';
import {Row, Col, Container} from "react-grid-system";
import getApi from "../../Helpers/Api"
import PricingPlanItem from "../PricingPlanItem/PricingPlanItem";

class PricingPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pricingPlans: [],
    }
  }

  componentDidMount() {
    getApi().getPricingPlans().then(response => {
      this.setState({
        pricingPlans: response.data.data.pricingPlans
      })
    });
  }

  render() {
    return (
      <Container>
        <Row>
          {this.state.pricingPlans.map((pricingPlan,key) => {
            return (
              <Col lg={6} key={key}>
                <PricingPlanItem pricingPlanData={pricingPlan} />
              </Col>
            )
          })}
        </Row>
      </Container>
    );
  }
}


export default PricingPlans;
