import React, {Component} from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import navigation from "./menu-items";
import Header from "./Components/Base/Header/Header";

class Routes extends Component {
  render() {
    let routes = [];
    let defaultPath = "dashboard";

    const urlParams = new URLSearchParams(window.location.search);
    let page = urlParams.get('page');

    if (page) {
      defaultPath = page.replace(/[^a-zA-Z-]+/, "");
    }

    navigation.items.map((item) => {
      if (item.hasOwnProperty('children')) {
        return this.getRoutesFromItem(item, routes);
      }

      return null;
    })

    return (
      <Switch>
        <Redirect exact from="/" to={"/" + defaultPath} key="dashboard"/>
        {routes}
      </Switch>
    );
  }

  getRoutesFromItem(item, routes) {
    return item.children.map((childItem, key) => {
      if (childItem.hasOwnProperty('component')) {
        routes.push(<Route exact path={childItem.url} key={routes.length}>
          <Header pageTitle={childItem.title}/>
          {childItem.component}
        </Route>);
      }

      if (childItem.hasOwnProperty('children')) {
        this.getRoutesFromItem(childItem, routes);
      }
    })
  }
}

export default Routes;