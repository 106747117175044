import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

let instance = null;

class Api {
  constructor() {
    if (!instance) {
      instance = this;
      this.client = axios.create({
        baseURL: "/Prod",
        timeout: 31000,
      });

      this.client.defaults.headers.common["Cache-Control"] = "no-cache";
    }

    return instance;
  }

  getSessionToken() {
    return getSessionToken(this.app).then(token => {
      return token;
    }).catch(error => {
      console.log("Error getting token");
      console.error(error);
    });
  }

  setApp(app) {
    this.app = app;
    this.getSessionToken().then(token => {
      this.client.defaults.headers.common["Authorization"] = "Bearer " + token;
    })
  }

  get(path, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig['headers']['Authorization'] = `Bearer ${token}`;
      return this.client.get(path, requestConfig).catch(error => {
        console.log('Error while calling ' + path);
        console.error(error);
      });
    })
  }

  post(path, data, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
      return this.client.post(path, data, requestConfig).catch(error => {
        console.log('Error while calling ' + path);
        console.error(error);
      })
    });
  }

  put(path, config) {

  }

  delete(path, config) {
    let requestConfig = config || {};
    if (!requestConfig.hasOwnProperty('headers')) {
      requestConfig.headers = {}
    }

    return this.getSessionToken().then(token => {
      requestConfig.headers['Authorization'] = `Bearer ${token}`;
      return this.client.delete(path, requestConfig).catch(error => {
        console.log('Error while deleting ' + path);
        console.error(error);
      })
    });
  }

  getAppConfig() {
      return this.get('config');
  }

  getFirstTimeInstalledTheme() {
      return this.get('theme-was-installed')
  }

  getTagsPaginated(params) {
      return this.get('tags', {params: params})
  }

  getAllTags(params) {
      return this.get('tags-all', {params: params})
  }

  addTag(data) {
      return this.post('tags', data)
  }

  deleteTags(data) {
      return this.delete('tags', {data: data})
  }

  getPricingGroups(params) {
    return this.get('pricing-groups', {params: params})
      .then((response =>  {
        response.data.data.Items = response.data.data.Items.map(pricingGroup => {
          if(pricingGroup.tags === undefined || !pricingGroup.tags) {
            pricingGroup.tags = [];
          }
          return pricingGroup;
        })

        return response;
      }))
      .catch(error => {
      console.log('Error while getting pricing groups');
      console.error(error);
    })
  }


  addPricingGroup(data) {
    return this.post('pricing-groups', data)
  }

  deletePricingGroup(data) {
    return this.delete('pricing-groups', {data: data})
  }

  getThemes() {
    return this.get('themes')
  }

  getProducts(params) {
    return this.get('products', {params: params})
  }

  getCollections(params) {
    return this.get('collections', {params: params})
  }

  installLiquid(themeId) {
      return this.get('liquid-install', {
        params: {themeId: themeId},
        timeout: 180000
      })
  }

  setAppAvailability(enabled) {
    return this.post('app-availability', {
      "app-enabled": enabled,
    })
  }

  setOrderTag(orderTag) {
    return this.post('set-order-tag', {
      "orderTag": orderTag,
    })
  }

  setCustomerTag(customerTag) {
    return this.post('set-customer-tag', {
      "customerTag": customerTag,
    })
  }

  getAppAvailability() {
    return this.get('app-availability').then(response => {
      return response.data.data.app_enabled;
    })
  }

  getProcessingData() {
    return this.get('processing-data').then(response => {
      return response.data.data;
    })
  }

  getPricingPlans() {
    return this.get('pricing-plans')
  }

  createPricingPlan(id) {
    return this.post('pricing-plans', {id: id})
  }

  changePricingGroupEnabled(SK, enabled) {
    return this.post('pricing-group-enabled', {
      data: {
        SK: SK,
        enabled: enabled,
      }
    })
  }

  sendHelpRequest(data) {
    return this.post('help-request', {
      data: data
    })
  }

  sendFeatureRequest(data) {
    return this.post('feature-request', {
      data: data
    })
  }

  sendThemeInstallRequest(data) {
    return this.post('theme-install-request', {
      data: data
    })
  }
}

const getApi = () => {
  return new Api();
}

export default getApi