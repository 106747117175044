import React from "react";
import {Container} from 'react-grid-system';
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getApi from "../../../Helpers/Api";
import styles from "./Header.module.scss";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {setAppEnabled} from "../../../redux/actions/appConfigActions";

class Header extends React.Component{

  constructor(params) {
    super(params);

    this.toggleAppStatus = this.toggleAppStatus.bind(this);
  }

  toggleAppStatus(checked) {
    this.props.setAppEnabled(!!checked);

    getApi().setAppAvailability(!!checked).catch(error => {
      console.error(error);
    })
  }

  renderStatus() {
    if (this.props.isThemeInstalling) {
      return <div className={styles.synchronizeText}>
        <FontAwesomeIcon style={{marginRight: "5px"}} size="sm" spin={true} icon={faSync} />
        <span>Theme is installing</span>
      </div>
    } else if (this.props.isSynchronizing) {
      return <div className={styles.synchronizeText}>
        <FontAwesomeIcon style={{marginRight: "5px"}} size="sm" spin={true} icon={faSync} />
        <span>Synchronizing</span>
      </div>
    }

    return "";
  }

  render() {
    return (
      <Container>
        <div className={styles.header}>
          <div className={styles.pageTitle}>
            <h2>{ this.props.pageTitle }</h2>
          </div>
          <div className={styles.headerItem}>
            {this.renderStatus()}
            <div className={styles.appSwitcher}>
              <input
                type="checkbox"
                id="switcher"
                name="switcher"
                checked={this.props.appEnabled} onChange={(e) => this.toggleAppStatus(e.target.checked)}
              />
              <label htmlFor="switcher">
                {this.props.appEnabled ? 'Enabled' : 'Disabled'}
              </label>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    isSynchronizing: state.appConfig.isSynchronizing,
    isThemeInstalling: state.appConfig.isThemeInstalling,
    appEnabled: state.appConfig.appEnabled,
  }
};

const mapDispatchToProps = {setAppEnabled}

export default connect(mapStateToProps, mapDispatchToProps)(Header);