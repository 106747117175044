import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './PricingPlanItem.module.scss';
import Button from "../Base/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getApi from "../../Helpers/Api"

class PricingPlanItem extends Component {
  choosePricePlan() {
    getApi().createPricingPlan(this.props.pricingPlanData.id).then(response => {
      if (response.data.hasOwnProperty("error") && response.data.error) {
        console.error(response.data.error);
      } else {

        if (window.top === window.self) {
          window.top.location.href = response.data.data.url;
        } else {
          window.top.location.href = response.data.data.url;

          let data = JSON.stringify({
            message: "Shopify.API.remoteRedirect",
            data: {location: response.data.data.url}
          })

          let url = new URL(response.data.data.url);

          window.parent.postMessage(data, "https://" + url.hostname)
        }
      }
    }).catch(error => {
      console.error(error);
    })
  }

  render() {
    let plan = this.props.pricingPlanData;

    let itemClasses = [styles.planItemWrapper];

    if (plan.isCurrent) {
      itemClasses = [...itemClasses, styles.currentPlan]
    }

    return (
      <div className={itemClasses.join(" ")}>
        <div className={styles.planHeader}>
          <div className={styles.planName}>
            {plan.name}
          </div>
          <div className={styles.planPrice}>
            ${plan.price} <span>/month</span>
          </div>
        </div>
        <div className={styles.planOptions}>
          <ul>
            { plan.options.map((option, key) => {
              return (
                <li key={key}>
                  <span>
                    <FontAwesomeIcon icon={['fas', 'check-circle']} />
                  </span>
                  {option}
                </li>
              )
            }) }
          </ul>
        </div>
        <div className={styles.planFooter}>
          {!plan.isCurrent ? plan.price === 0 ? <Button content="Free Plan" onClick={() => {return false}} /> : <Button content="Choose Plan" variant="btnPrimary" onClick={() => {this.choosePricePlan()}} />
          :  <Button content="Current Plan" onClick={() => {return false}} />}
        </div>
      </div>
    );
  }
}

PricingPlanItem.propTypes = {
  pricingPlanData: PropTypes.object.isRequired,
};

export default PricingPlanItem;
