import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './redux/reducers/rootReducer'

function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer
    })

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./redux/reducers/rootReducer', () => store.replaceReducer(rootReducer))
    }

    return store
}

export default configureAppStore()