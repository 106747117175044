import React from 'react';
import EntitySelector from "./EntitySelector/EntitySelector";
import PropTypes from "prop-types"
import getApi from "../../Helpers/Api";
import styles from "./EntitiesSelector.module.scss";
import formStyles from "../Base/Form/Form.module.scss"

let EntitiesSelector = ({clearErrors, ...props}) => {

  const entitySelected = (entity) => {
    props.setPricingGroupProperty('selectedEntityType', entity);
    clearErrors(); //Need for re-render
  }

  const goBack = () => {
    entitySelected(false);
  }

  const renderEntities = () => {
    return (
      <>
        <div className={styles.entitiesSelector}>
          <div className={styles.title}>
            Entity type
          </div>
          <ul className={styles.entitiesList}>
            <li onClick={()=>{entitySelected("Products")}}>Products</li>
            <li onClick={()=>{entitySelected("Collections")}}>Collections</li>
          </ul>
        </div>
      </>
    );
  }

  let block = '';

  switch (props.getPricingGroupDataByProperty('selectedEntityType')) {
    case 'Products':
      block = <EntitySelector
        allEntitiesSelected={!!props.getPricingGroupDataByProperty('allEntitiesSelected')}
        setAllEntitiesSelected={allEntitiesSelected => props.setPricingGroupProperty('allEntitiesSelected', allEntitiesSelected)}
        goBack={goBack}
        getEntity={params => getApi().getProducts(params)}
        entityName="product"
        selectedEntities={props.selectedProducts}
        setSelectedEntities={(selectedIds) => props.setSelectedProducts(selectedIds)}
        groupNameErrror={props.groupNameError}
      />
      break;
    case 'Collections':
      block = <EntitySelector
        allEntitiesSelected={!!props.getPricingGroupDataByProperty('allEntitiesSelected')}
        setAllEntitiesSelected={allEntitiesSelected => props.setPricingGroupProperty('allEntitiesSelected', allEntitiesSelected)}
        goBack={goBack}
        getEntity={params => getApi().getCollections(params)}
        entityName="collection"
        selectedEntities={props.selectedCollections}
        setSelectedEntities={(selectedIds) => props.setSelectedCollections(selectedIds)}
        groupNameErrror={props.errors[props.name]}
      />
      break;
    default:
      block = renderEntities();
  }

  return <>
    {block}
    {
      (props.errors[props.name]) && (<span className={formStyles.formError}>{props.errors[props.name].message}</span>)
    }
  </>
}

EntitiesSelector.propTypes = {
  setPricingGroupProperty: PropTypes.func.isRequired,
  getPricingGroupDataByProperty: PropTypes.func.isRequired,
  groupNameError: PropTypes.string
}

export default EntitiesSelector;