import React, {useState} from 'react';
import Form from "../Base/Form/Form";
import FormInput from "../Base/Form/FormInput/FormInput";
import {Col, Container, Row} from "react-grid-system";
import Card from "../Base/Parts/Card";
import * as yup from "yup";
import FormTextarea from "../Base/Form/FormTextarea/FormTextarea";
import getApi from "../../Helpers/Api";
import helpBanner from "../../assets/images/help_banner.png";
import styles from "./Help.module.scss";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

let Help = () => {
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [showSentFeatureMessage, setSentFeatureMessage] = useState(false);
  const [savingProcess, setSavingProcess] = useState(false);
  const [savingFeatureProcess, setSavingFeatureProcess] = useState(false);

  const handleSubmit = (data) => {
    setSavingProcess(true);
    return getApi().sendHelpRequest(data).then(() => {
      setShowSentMessage(true)
      setSavingProcess(false);

      setTimeout(() => {
        setShowSentMessage(false);
      }, 3000)
    });
  }

  const handleFeatureSubmit = (data) => {
    setSavingFeatureProcess(true);
    return getApi().sendFeatureRequest(data).then(() => {
      setSentFeatureMessage(true)
      setSavingFeatureProcess(false);

      setTimeout(() => {
        setSavingFeatureProcess(false);
      }, 3000)
    });
  }

  const renderSentMessage = () => {
    if (!showSentMessage) {
      return "";
    }

    return <div className="noticeBadge noticeSuccess">You request has been sent</div>
  }

  const renderSentFeatureMessage = () => {
    if (!showSentFeatureMessage) {
      return "";
    }

    return <div className="noticeBadge noticeSuccess">You request has been sent</div>
  }

  const validationRules = yup.object().shape({
    subject: yup.string().required("This field is required!"),
    body: yup.string().required("This field is required!").test('len', "Body should have more than 5 symbols", val => val.toString().length > 5)
  })

  const validationFeatureRules = yup.object().shape({
    subject: yup.string().required("This field is required!"),
    body: yup.string().required("This field is required!").test('len', "Body should have more than 5 symbols", val => val.toString().length > 5)
  })

  const formMethods = useForm({
    resolver: yupResolver(validationRules),
    reValidateMode: "onSubmit",
  });

  const formFeatureMethods = useForm({
    resolver: yupResolver(validationFeatureRules),
    reValidateMode: "onSubmit",
  });

  return (
    <div className="pageContent">
      <Container>
        <Row>
          <Col lg={4} sm={6}>
            <div className={styles.helpCard}>
              <div className={styles.helpCardBanner}>
                <img src={helpBanner} alt="PBC Help"/>
              </div>
              <div className={styles.helpCardContent}>
                <p>
                  We are striving to make our app better every day - and it's not possible
                  without you, our beloved client. <br/>
                  We know that even perfect solutions have glitches but what is important
                  - how fast you react. That's why we provide 24/7 availability +guaranteed
                  email reply in less than 12 hours.
                </p>
                <p>
                  If you have any difficulties with the installation, request a feature or simply
                  say hi - our Support team is ready to assist you. Feel free to submit a form below
                  or just ping us in a <span onClick={() => {window.tidioChatApi.open()}}>live chat</span>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} sm={6}>
            <Card cardTitle="Help">
              <Form
                submitLabel="Send"
                onSubmit={handleSubmit}
                formMethods={formMethods}
                savingProcess={savingProcess}
              >
                <FormInput
                  name="subject"
                  type="text"
                  label="Subject"
                />
                <FormTextarea
                  name="body"
                  label="How can we help you? (please indicate your question here)"
                  rows={9}
                />
              </Form>
              {renderSentMessage()}
            </Card>
          </Col>
          {/*<Col lg={6} sm={6}>*/}
          {/*  <Card cardTitle="Feature Request">*/}
          {/*    <Form*/}
          {/*      submitLabel="Send"*/}
          {/*      onSubmit={handleFeatureSubmit}*/}
          {/*      formMethods={formFeatureMethods}*/}
          {/*      savingProcess={savingFeatureProcess}*/}
          {/*    >*/}
          {/*      <FormInput*/}
          {/*        name="subject"*/}
          {/*        type="text"*/}
          {/*        label="Subject"*/}
          {/*      />*/}
          {/*      <FormTextarea*/}
          {/*        name="body"*/}
          {/*        label="Feature you would like to see"*/}
          {/*        rows={9}*/}
          {/*      />*/}
          {/*    </Form>*/}
          {/*    {renderSentFeatureMessage()}*/}
          {/*  </Card>*/}
          {/*</Col>*/}
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}

export default Help;