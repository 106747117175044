const customStyles = {
  headRow: {
    style: {
      minHeight: '50px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      fontSize: "15px",
    }
  },
  rows: {
    style: {
      minHeight: '60px',
    }
  },
  headCells: {
    style: {
      padding: '12px',
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: '20px',
    },
  },
  cells: {
    style: {
      padding: '12px',
      fontSize: '15px'
    },
  },
};

export default customStyles;