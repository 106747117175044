import React, {Component} from 'react';
import PropTypes from "prop-types"
import styles from "../Table.module.scss"
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowsPerPage: 10,
            options: [
              {label: "5", value: 5},
              {label: "10", value: 10},
              {label: "15", value: 15},
            ]
        }

        this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    }

    onChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: event.target.value,
        })

        this.props.onChangeRowsPerPage(event.target.value)
    }

    render() {
        let prevBtnClass = [styles.btnPagination];
        let nextBtnClass = [styles.btnPagination];

        if (!this.props.prevActive) {
          prevBtnClass = [...prevBtnClass, styles.btnDisabled];
        }
        if (!this.props.nextActive) {
          nextBtnClass = [...nextBtnClass, styles.btnDisabled];
        }

        return (
          <div className={styles.tablePagination}>

            <div className={styles.itemsPerPage}>
              <span>Rows per page </span>
              <select name="rowsPerPage" onChange={this.onChangeRowsPerPage} defaultValue={this.state.rowsPerPage}>
                {
                  this.state.options.map((option, key) => {
                    return <option key={key} value={option.value}>{option.label}</option>
                  })
                }
              </select>
            </div>

            <div className={styles.paginationButtons}>
              <button className={prevBtnClass.join(" ")}
                      disabled={!this.props.prevActive}
                      onClick={() => this.props.onChangePage(false)}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button className={nextBtnClass.join(" ")}
                      disabled={!this.props.nextActive}
                      onClick={() => this.props.onChangePage(true)}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        );
    }
}

Pagination.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  prevActive: PropTypes.bool,
  nextActive: PropTypes.bool,
}
export default Pagination;