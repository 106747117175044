import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from "./Sidebar.module.scss";
import navigation from "../../menu-items";
import NavGroup from "./NavGroup/NavGroup";

class Sidebar extends React.Component{

  constructor(props) {
    super(props);

    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    this.props.setSidebarExpanded(!this.props.expanded);
  }


  render() {

    let sidebarClasses = [styles.sideNavigation];

    if(!this.props.expanded) {
      sidebarClasses = [...sidebarClasses, styles.collapsed];
    }

    return (
      <div className={sidebarClasses.join(" ")}>

        {/*<NavLogo />*/}

        {navigation.items.map( (item, key) => {
          switch ( item.type ) {
            case 'group':
              return (
                  <NavGroup sidebarExpanded={this.props.expanded} key={key} navigation={item} />
                );
            default:
              return false;
          }
        })}

        <div className={styles.collapseToggle}>
          <span onClick={this.handleExpand}>
            <FontAwesomeIcon icon={this.props.expanded ? faChevronLeft : faChevronRight} />
          </span>
        </div>

      </div>
    )
  }
}

export default Sidebar;