import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "../Form.module.scss";
import PropTypes from "prop-types"
import {Controller} from "react-hook-form";

let FormMultiSelect = ({ register, name, options, onChange, errors, ...props }) => {
  const animated = makeAnimated()

  const handleChange = (value) => {
    onChange(value.filter(option => option.value).map(option => option.value));
  }
  const getValue = () => {
    return (props.value || []).map(value => {
      return options.filter(option => option.value === value)[0]
    })
  }

  let inputClasses = [styles.reactSelectContainer]
  let formGroupClasses = [styles.formGroup]

  if (props.singleElement) {
    return (
      <>
        <div className={formGroupClasses.join(" ")}>
          { props.label ? (
            <label className={styles.formLabel} htmlFor={name}>{props.label}</label>
          ) : "" }
            <Select
              name={name}
              closeMenuOnSelect={false}
              components={[animated]}
              onChange={handleChange}
              value={getValue()}
              className={inputClasses.join(" ")}
              isMulti
              classNamePrefix="form"
              options={options}
            />
          {
            (errors[name])
              ?
              <span className={styles.formError}>{errors[name].message}</span>
              : ''
          }
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={formGroupClasses.join(" ")}>
          { props.label ? (
            <label className={styles.formLabel} htmlFor={name}>{props.label}</label>
          ) : "" }
          <Controller name={name}
                      isClearable
                      control={props.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          closeMenuOnSelect={false}
                          components={[animated]}
                          className={inputClasses.join(" ")}
                          isMulti
                          classNamePrefix="form"
                          options={options}
                        />
                      )} />
          {
            (errors[name])
              ?
              <span className={styles.formError}>{errors[name].message}</span>
              : ''
          }
        </div>
      </>
    );
  }


}

FormMultiSelect.propTypes = {
  validationError: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.any,
}

export default FormMultiSelect;
