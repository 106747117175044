import React from "react";
import DataTable from "react-data-table-component";
import customStyles from "./TableTheme";
import styles from "./Table.module.scss";
import Pagination from "./Pagination/Pagination";
import Loader from "../Loader/Loader";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : false,
      pagesHistory: ['false'],
    };

    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this)
  }

  onChangePage(isNext) {
    let history = this.state.pagesHistory;
    if (isNext) {
      history.push(this.props.nextFromKey)
    } else {
      history.pop();
    }

    this.setState({
      pagesHistory: history,
    })

    let fromKey = isNext ? this.props.nextFromKey : this.getPrevFormKey()
    this.props.onChangePage(fromKey);
  }

  onChangeRowsPerPage(rowsPerPage) {
    this.setState({
      pagesHistory: ['false'],
    })
    this.props.onChangeRowsPerPage(rowsPerPage)
  }

  getPrevFormKey() {
    return this.state.pagesHistory[this.state.pagesHistory.length - 1] !== undefined ? this.state.pagesHistory[this.state.pagesHistory.length - 1] : false;
  }

  prevActive() {
    return this.state.pagesHistory[this.state.pagesHistory.length - 1] !== undefined ? this.state.pagesHistory[this.state.pagesHistory.length - 1] !== "false" : false;
  }

  render() {

    return (
      <div className={styles.pbcTable}>
        <DataTable
          customStyles={customStyles}
          progressComponent={<Loader />}
          {...this.props}
        />
        {this.state.rowsPerPage && <Pagination
          rowsPerPage={this.state.rowsPerPage}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          prevActive={this.prevActive()}
          nextActive={!!this.props.nextFromKey}
        />}
      </div>
    )
  }
}

export default Table;