import React from "react";
import styles from "../Form.module.scss";
import PropTypes from "prop-types"
let FormInput = (props) => {
  let {register, value, type, name, label, singleElement, errors, ...additionalProps} = props
  if (singleElement) {
    return (
      <div className={styles.formGroup}>
        <label className={styles.formLabel} htmlFor={name}>{label}</label>
        <input type={type} className={styles.formControl} {...additionalProps} />
      </div>
    )
  } else {
    return (
      <div className={styles.formGroup}>
        <label className={styles.formLabel} htmlFor={name}>{label}</label>
        <input {...register(name)} defaultValue={value} type={type} className={styles.formControl} />
        {
          (errors[name]) && (<span className={styles.formError}>{errors[name].message}</span>)
        }
      </div>
    )
  }
}

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  singleElement: PropTypes.bool,
  name: PropTypes.string,
}

export default FormInput;
