import React from "react";
import PropTypes from "prop-types";
import styles from "./Card.module.scss";

class Card extends React.Component{

  render() {
    let classes = [styles.card];
    if (this.props.autoHeight) {
      classes.push(styles.autoHeight)
    }

    return (
      <div className={classes.join(" ")}>
        {
          this.props.cardTitle ? (
            <div className={styles.cardTitle}>
              <h3>{this.props.cardTitle}</h3>
              {
                this.props.cardSubtitle ? (
                  <p>{this.props.cardSubtitle}</p>
                ) : ""
              }
            </div>
          ) : ""
        }
        <div className={styles.cardContent}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  cardTitle: PropTypes.string,
  cardSubtitle: PropTypes.string,
}

export default Card;