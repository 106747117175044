import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Controller } from "react-hook-form";
import styles from "../Form.module.scss";
import PropTypes from "prop-types"

let FormSelect = ({ name, options, onChange, errors, ...props }) => {
  const animated = makeAnimated();

  const handleChange = (value) => {
    onChange(value.value);
  }

  let inputClasses = [styles.reactSelectContainer]
  let formGroupClasses = [styles.formGroup]

  if (props.singleElement) {
    formGroupClasses = [...formGroupClasses, styles.singleElement]

    return (
      <div className={formGroupClasses.join(" ")}>
        { props.label ? (
          <label className={styles.formLabel} htmlFor={name}>{props.label}</label>
        ) : "" }
        <Select
          closeMenuOnSelect={true}
          components={[animated]}
          onChange={handleChange}
          value={options.filter(option => option.value === props.value)[0]}
          className={inputClasses.join(" ")}
          classNamePrefix="form"
          options={options}
          name={name}
        />
      </div>
    );
  } else {
    return (
      <div className={formGroupClasses.join(" ")}>
        { props.label ? (
          <label className={styles.formLabel} htmlFor={name}>{props.label}</label>
        ) : "" }
        <Controller name={name}
                    isClearable
                    control={props.control}
                    render={({ field }) => (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={[animated]}
                          className={inputClasses.join(" ")}
                          classNamePrefix="form"
                          options={options}
                        />
                      )} />
        {
          (errors[name])
            ?
            <span className={styles.formError}>{errors[name].message}</span>
            : ''
        }
      </div>
    );
  }

}

FormSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  single: PropTypes.bool
}

export default FormSelect;
