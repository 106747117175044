import React, {Component} from 'react';
import Card from "../Base/Parts/Card";
import getApi from "../../Helpers/Api";
import {Col, Container, Row} from "react-grid-system";
import {connect} from "react-redux";
import {setIsSynchronizing} from "../../redux/actions/appConfigActions";
import Button from "../Base/Button/Button";
import Modal from "../Base/Modal/Modal";
import Table from "../Base/Table/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PricingGroupForm from "../PricingGroupForm/PricingGroupForm";
import Loader from "../Base/Loader/Loader";
import formStyles from "../Base/Form/Form.module.scss"

import styles from "./PricingGroups.module.scss"

class PricingGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      rowsPerPage: 10,
      progressPending: true,
      showForm: false,
      showDeletePopup: false,
      currentFromKey: false,
      nextFormKey: false,
      curentRowData: false,
      checkboxUpdating: {},
      selectedPricingGroups: [],
      clearSelectedRowsValue: false,
    }

    this.modalClose = this.modalClose.bind(this);
    this.modalDeleteClose = this.modalDeleteClose.bind(this);
    this.updateData = this.updateData.bind(this);
    this.afterSave = this.afterSave.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.setCheckboxUpdating = this.setCheckboxUpdating.bind(this);
    this.selectEntities = this.selectEntities.bind(this);
    this.deletePricingGroups = this.deletePricingGroups.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  onPageChange(fromKey) {
    this.setState({
      currentFromKey: fromKey
    }, () => {
      this.updateData();
    })
  }

  onRowsPerPageChange(rowsPerPage) {
    this.setState({
      rowsPerPage: rowsPerPage,
    }, () => {
      this.updateData();
    })
  }

  afterSave() {
    this.props.setIsSynchronizing(true);
    this.updateData();
    this.modalClose();
  }

  updateData() {
    this.setState({
      progressPending: true,
    })

    getApi().getPricingGroups({perPage: this.state.rowsPerPage, fromKey: this.state.currentFromKey}).then(async response => {
      const newKey = response.data.data.LastEvaluatedKey !== undefined ? response.data.data.LastEvaluatedKey : false;
      this.setState({
        progressPending: false,
        data: await this.processPricingGroups(response.data.data.Items),
        nextFormKey: newKey,
      });

    }).catch(() => {
      this.setState({
        progressPending: false,
      })
    })
  }

  modalClose() {
    this.setState({
      showForm: false,
      curentRowData: {},
    })
  }

  modalDeleteClose() {
    this.setState({
      showDeletePopup: false,
    })
  }

  processPricingGroups(pricingGroups) {
    return Promise.all(pricingGroups.map((pricingGroup) => {
      return pricingGroup;
    }));
  }

  showEditForm(row) {
    this.setState({
      curentRowData: row,
      showForm: true,
    })
  }

  setPricingGroupEnabled(SK, enabled) {
    let data = this.state.data.map(row => {
      if (row.SK === SK) {
        row.enabled = enabled;
      }

      return row;
    });

    this.setState({
      data: data,
    })
  }

  changePricingGroupEnabled(value, SK) {
    this.setCheckboxUpdating(SK, true);
    getApi().changePricingGroupEnabled(SK, value).catch(error => {
      console.error(error);
    }).then(() => {
      this.props.setIsSynchronizing(true);
      this.setCheckboxUpdating(SK, false);
      this.setPricingGroupEnabled(SK, value);
    })
  }

  setCheckboxUpdating(SK, updating) {
    let checkboxUpdating = this.state.checkboxUpdating;
    checkboxUpdating[SK] = updating;

    this.setState({
      checkboxUpdating: checkboxUpdating,
    })
  }

  getCheckboxUpdating(SK) {
    return this.state.checkboxUpdating[SK] ? this.state.checkboxUpdating[SK] : false
  }

  selectEntities(items) {
    this.setState({selectedPricingGroups: items.selectedRows})
  }

  deletePricingGroups() {
    if (this.state.progressPending) {
      return false;
    }

    this.setState({
      progressPending: true,
    });

    getApi().deletePricingGroup(this.state.selectedPricingGroups.map(pricingGroup => ({SK: pricingGroup.SK}))).then(() => {
      this.setState({
        selectedPricingGroups: [],
        clearSelectedRowsValue: !this.state.clearSelectedRowsValue,
        showDeletePopup: false,
        progressPending: true,
      });

      this.updateData();
    })
  }

  renderEnabledCheckbox(row) {
    return this.getCheckboxUpdating(row.SK)
      ?  <Loader height="sm" />
      : <div className={formStyles.tagSwitcher}>
          <input type="checkbox" id={"switcher-" + row.SK} name={"enabled-" + row.SK}
                 checked={row.enabled !== undefined ? row.enabled : true}
                 onChange={(event) => this.changePricingGroupEnabled(event.target.checked, row.SK)}/>
          <label htmlFor={"switcher-" + row.SK} />
        </div>
  }

  render() {
    return (
      <div className="pageContent">
        <Container>
          <Row>
            <Col md={12}>
              <Card>
                <div className="tableActions textRight mb2">
                  {this.state.selectedPricingGroups.length > 0 && <span className={styles.btnDangerWrapper}><Button
                    content={"Delete pricing group" + (this.state.selectedPricingGroups.length > 1 ? "s" : "")}
                    onClick={() => {this.setState({showDeletePopup: true})}}
                    variant="btnDanger"
                  /></span>}
                  <Button onClick={() => this.setState({showForm: true})}
                          content="Add Pricing group"
                          variant="btnPrimary"
                  />
                </div>
                <Table
                  title="Pricing groups"
                  selectableRows={true}
                  selectableRowsHighlight={true}
                  onSelectedRowsChange={this.selectEntities}
                  clearSelectedRows={this.state.clearSelectedRowsValue}
                  progressPending={this.state.progressPending}
                  keyField="SK"
                  striped={true}
                  noHeader={true}
                  subHeader={false}
                  sortIcon={<FontAwesomeIcon icon={"sort-up"} />}
                  paginationServer={true}
                  onChangePage={this.onPageChange}
                  onChangeRowsPerPage={this.onRowsPerPageChange}
                  rowsPerPage={this.state.rowsPerPage}
                  nextFromKey={this.state.nextFormKey}
                  columns={[
                    {
                      name: 'Group Name',
                      selector: 'pricingGroupName',
                      sortable: true,
                    },
                    {
                      name: 'Tags',
                      cell: row => row.tags.map(tag => tag.value).join(", "),
                    },
                    {
                      name: 'Type',
                      selector: 'selectedEntityType',
                      right: true
                    },
                    {
                      name: 'Status',
                      cell: row => this.renderEnabledCheckbox(row),
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    },
                    {
                      cell: row => <Button content="Edit" onClick={() => this.showEditForm(row)} />,
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    }
                  ]}
                  data={this.state.data} />
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          onClose={this.modalClose}
          title="Add Pricing group"
          show={this.state.showForm}
          content={<PricingGroupForm afterSave={this.afterSave} curentRowData={this.state.curentRowData} />}
        />
        <Modal
          onClose={this.modalDeleteClose}
          title={"Are you sure want to delete a customer tag" + (this.state.selectedPricingGroups.length > 1 ? "s" : "") + "?"}
          show={this.state.showDeletePopup}
          additionalClass="modalDelete"
          content={
            <Row>
              <Col md={6}>
                <div className="textCenter">
                  <Button content="Cancel" onClick={()=>{this.setState({showDeletePopup: false})}} />
                </div>
              </Col>
              <Col md={6}>
                <div className="textCenter">
                  <Button content="Delete" variant="btnDanger" isLoading={this.state.progressPending} onClick={this.deletePricingGroups} />
                </div>
              </Col>
          </Row>
        }
      />
      </div>
    );
  }
}

const mapDispatchToProps = {setIsSynchronizing}

export default connect(() => ({}), mapDispatchToProps)(PricingGroups);