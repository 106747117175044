import React from "react";
import Sidebar from './Components/Sidebar/Sidebar';
import styles from './App.module.scss';
import Routes from "./Routes";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {setIsSynchronizing, setIsThemeInstalling, setAppEnabled} from "./redux/actions/appConfigActions";
import {connect} from "react-redux";
import createApp from "@shopify/app-bridge";
import getApi from "./Helpers/Api";
import { Inspectlet } from "pbc-apps-components";

const urlParams = new URLSearchParams(window.location.search);
let host = urlParams.get('host');

const shopifyConfig = {
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  host: host,
}

const app = createApp(shopifyConfig);
getApi().setApp(app);
Inspectlet.setInitialData(app);

const SYNCHRONIZE_CHECK_INTERVAL = 30000;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarExpanded: true,
      focused: true,
    }

    this.interval = false;

    this.setSidebarExpanded = this.setSidebarExpanded.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.getProcessingData = this.getProcessingData.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.allowedToGetProcessingData = this.allowedToGetProcessingData.bind(this);
    this.getAvailability = this.getAvailability.bind(this);
  }

  onBlur() {
    this.setState({
      focused: false,
    });
  }

  onFocus() {
    this.setState({
      focused: true,
    });
  }

  allowedToGetProcessingData() {
    return this.state.focused;
  }

  componentDidMount() {
    if(window.innerWidth < 1199) {
      this.setState({
        sidebarExpanded: false
      })
    }

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('blur', this.onBlur);
    window.addEventListener('focus', this.onFocus);

    this.startSynchronizingCheckInterval();
    this.getAvailability();
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
    window.removeEventListener('blur', this.onBlur);
    window.removeEventListener('focus', this.onFocus);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isSynchronizing !== this.props.isSynchronizing && this.props.isSynchronizing) {
      this.startSynchronizingCheckInterval();
    }
  }

  startSynchronizingCheckInterval() {
    this.getProcessingData().then(synchronizing => {
      if (synchronizing) {
        if (this.interval) {
          console.log("Clearing in");
          clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
          this.allowedToGetProcessingData() && this.getProcessingData().then(processingData => {
            !processingData.synchronizing && !processingData.themeInstalling && clearInterval(this.interval);
          })
        }, SYNCHRONIZE_CHECK_INTERVAL)
      }
    })
  }

  getProcessingData() {
    return getApi().getProcessingData().then(processingData => {
      this.props.setIsThemeInstalling(processingData.themeInstalling);
      this.props.setIsSynchronizing(processingData.synchronizing);

      return processingData;
    }).catch(error => {
      console.error(error);

      return {themeInstalling: false, synchronizing: false}
    })
  }

  getAvailability() {
    getApi().getAppAvailability().then(enabled => {
      this.props.setAppEnabled(!!enabled);
    }).catch(error => {
      console.error(error);
    })
  }

  handleResize(){
    if(window.innerWidth < 1199) {
      this.setState({
        sidebarExpanded: false
      })
    }
  }

  setSidebarExpanded(value) {
    this.setState({
      sidebarExpanded: value
    })
  }

  render() {
    let wrapperClasses = [styles.mainWrapper];
    let pageWrapperClasses = [styles.pageWrapper];

    if(!this.state.sidebarExpanded) {
      pageWrapperClasses = [...pageWrapperClasses, styles.sidebarCollapsed];
    }

    return (
      <Router>
        <div className={wrapperClasses.join(" ")}>
          <Sidebar setSidebarExpanded={this.setSidebarExpanded} expanded={this.state.sidebarExpanded}/>
          <div className={pageWrapperClasses.join(" ")}>
            <Routes />
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSynchronizing: state.appConfig.isSynchronizing,
    isThemeInstalling: state.appConfig.isThemeInstalling,
    appEnabled: state.appConfig.appEnabled,
  }
};

const mapDispatchToProps = {setIsSynchronizing, setIsThemeInstalling, setAppEnabled}

export default connect(mapStateToProps, mapDispatchToProps)(App);
