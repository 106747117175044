import React from "react";
import Submit from "./Submit/Submit";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

const getChangedElement = (child, register, errors, control) => {
  if (child.props.name && (child.props.singleElement === undefined || !child.props.singleElement)) {

    return React.cloneElement(child, {
      errors,
      register,
      control,
      key: child.props.name
    })
  }

  return child
}

const Form = ({ children, onSubmit, submitLabel, savingProcess, formMethods, ...props }) => {
  const { handleSubmit, control, register, formState: {errors} } = formMethods

  const formOnSubmit = (params) => {
    if (!savingProcess) {
      onSubmit(params);
    }
  }

  return (
    <form onSubmit={handleSubmit(formOnSubmit)}>
      {Array.isArray(children)
        ? children.map((child) => {
          return getChangedElement(child, register, errors, control);
        })
        : getChangedElement(children, register, errors, control)}

      <div className="textCenter">
        <Submit
          submitLabel={submitLabel}
          btnVariant="btnPrimary"
          savingProcess={savingProcess}
        />
      </div>
    </form>
  );
}

Form.propTypes = {
  submitLabel: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savingProcess: PropTypes.bool,
  formMethods: PropTypes.object.isRequired,
}

export default Form;
