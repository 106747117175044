import React, {Component} from 'react';
import FormCheckbox from "../../Base/Form/FormCheckbox/FormCheckbox";
import Table from "../../Base/Table/Table";
import Pagination from "../Pagination/Pagination";
import Button from "../../Base/Button/Button";
import PropTypes from "prop-types";
import styles from "../EntitiesSelector.module.scss";
import emptyPlaceholder from "../../../assets/images/placeholderImage.png";
import FormInput from "../../Base/Form/FormInput/FormInput";
import formStyles from "../../Base/Form/Form.module.scss";

const SEARCH_INTERVAL = 500;

class EntitySelector extends Component {
  constructor(props) {
    super(props);

    this.pageChanged = false;

    this.state = {
      hasPreviousPage: false,
      hasNextPage: false,
      before: false,
      after: false,
      searchValue: "",
      searchTimeout: false,
      entities: [],
      allEntitiesSelected: false,
    }

    this.checkboxChange = this.checkboxChange.bind(this);
    this.selectEntities = this.selectEntities.bind(this);
    this.onNextPage = this.onNextPage.bind(this);
    this.onPrevPage = this.onPrevPage.bind(this);
    this.searchChange = this.searchChange.bind(this);
  }

  componentDidMount() {
    this.updateData();
    this.setState({
      allEntitiesSelected: this.props.allEntitiesSelected
    })
  }

  onNextPage() {
    this.updateData(this.state.after);
  }

  onPrevPage() {
    this.updateData(false, this.state.before);
  }

  updateData(after, before) {
    this.props.getEntity({after: after, before: before, search: this.state.searchValue}).then(response => {
      this.setState({
        entities: this.processEntities(response.data.data),
        hasPreviousPage: response.data.pageInfo.hasPreviousPage,
        hasNextPage: response.data.pageInfo.hasNextPage,
        before: response.data.pageInfo.before,
        after: response.data.pageInfo.after,
      });

      if (after || before) {
        this.pageChanged = true;
      }

      this.selectEntities({selectedRows: this.props.selectedEntities.map(id => {
          return {id}
        })})
    }).catch(error => console.log(error))
  }

  processEntities(entities) {
    return entities.map(entity => {
      entity.image = <div className={styles.tableImageCell}>
        <img height={50} src={entity.image || emptyPlaceholder} alt={entity.title} />
      </div>;

      if (this.props.selectedEntities && this.props.selectedEntities.includes(entity.id)) {
        entity.selected = true;
      }

      return entity;
    });
  }

  checkboxChange(value) {
    this.props.setAllEntitiesSelected(value);
    this.setState({
      allEntitiesSelected: value
    })
  }

  selectEntities(items) {
    if (this.pageChanged) {
      this.pageChanged = false;

      return;
    }

    let selectedEntities = [...this.props.selectedEntities, ...items.selectedRows.map(entityRow => entityRow.id)]
    //Remove duplicates
    selectedEntities = selectedEntities.filter((item, index) => selectedEntities.indexOf(item) === index)
    let entities = this.state.entities;

    entities = entities.map(entity => {
      for (let item of items.selectedRows) {
        if (item.id === entity.id) {
          entity.selected = true;

          return entity;
        }
      }

      entity.selected = false;
      let entityIndex = selectedEntities.indexOf(entity.id)
      if (entityIndex !== -1) {
        selectedEntities.splice(entityIndex, 1)
      }

      return entity;
    })

    this.setState({
      entities: entities
    })

    this.props.setSelectedEntities(selectedEntities)
  }

  isEntitySelected(entity) {
    return entity.selected !== undefined ? entity.selected : false;
  }

  searchChange(event) {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({
      searchValue: event.target.value,
      searchTimeout: setTimeout(() => {
        this.updateData();
      }, SEARCH_INTERVAL)
    })
  }

  render() {
    let allEntitiesSelected = !!this.state.allEntitiesSelected;

    return (
      <>
        <FormCheckbox
          label="Select all entities in the store"
          name="all-entities"
          value={this.state.allEntitiesSelected}
          onChange={this.checkboxChange}
          singleElement={true}
        />
        {!allEntitiesSelected && <>
          <FormInput singleElement={true} onChange={this.searchChange} attributes={{placeholder: "Type in to search"}} type="text"/>
          <div className="popupTable">
            <Table
              title={"Select "+this.props.entityName+"s"}
              striped={true}
              noHeader={true}
              subHeader={false}
              highlightOnHover={true}
              pointerOnHover={true}
              noTableHead={true}
              selectableRows={true}
              selectableRowsHighlight={true}
              onSelectedRowsChange={this.selectEntities}
              selectableRowSelected={row => {
                return this.isEntitySelected(row);
              }}
              // selectableRowsComponent={<Checkbox label={} name={} />}
              columns={[
                {
                  name: 'Image',
                  selector: 'image',
                  sortable: false,
                  width: "80px",
                  hide: "sm",
                },{
                  name: 'Title',
                  selector: 'title',
                  sortable: false,
                },
              ]}
              data={this.state.entities} />
            <div className={styles.popupTableActions}>
              <Button content="Back" onClick={this.props.goBack} />
              <Pagination
                prevActive={this.state.hasPreviousPage}
                nextActive={this.state.hasNextPage}
                onPrevPage={this.onPrevPage}
                onNextPage={this.onNextPage}
              />
            </div>
          </div>
        </>
        }
        {
          this.props.groupNameError ? (
            <span className={formStyles.formError}>{this.props.groupNameError}</span>
          ) : ''
        }
      </>
    );
  }
}

EntitySelector.propTypes = {
  allEntitiesSelected: PropTypes.bool,
  setAllEntitiesSelected: PropTypes.func,
  goBack: PropTypes.func,
  getEntity: PropTypes.func,
  entityName: PropTypes.string.isRequired,
  selectedEntities: PropTypes.array,
  setSelectedEntities: PropTypes.func,
  groupNameError: PropTypes.string
}

export default EntitySelector;
