import React, {Component} from 'react';
import {Col, Container, Row} from "react-grid-system";
import {connect} from "react-redux";
import {setIsThemeInstalling} from "../../redux/actions/appConfigActions";
import Card from "../Base/Parts/Card";
import Button from "../Base/Button/Button";
import getApi from "../../Helpers/Api";
import FormSelect from "../Base/Form/FormSelect/FormSelect";
import formStyles from "../Base/Form/Form.module.scss";
import FormCheckbox from "../Base/Form/FormCheckbox/FormCheckbox";

class ThemeInstallation extends Component {

    constructor(props) {
        super(props);

        this.state = {
          themeIdManual: false,
          themeIdAutomatic: false,
          themesOptions: [],
          updatedText: "",
          manualUpdatedText: "",
          updating: false,
          createdBackup: false,
        }

        this.themeChangedAutomatic = this.themeChangedAutomatic.bind(this)
        this.themeChangedManual = this.themeChangedManual.bind(this)
        this.getThemes = this.getThemes.bind(this)
        this.updateTheme = this.updateTheme.bind(this)
        this.manualUpdateTheme = this.manualUpdateTheme.bind(this)
        this.showErrorManual = this.showErrorManual.bind(this)
    }

    componentDidMount() {
        this.getThemes();
    }

    getThemes() {
      getApi().getThemes().then(response => {
        let options = [];
        let themes = response.data.data.sort((a, b) => {
          return a.role === 'main' ? -1 : 1;
        })

        themes.map(theme => {
          options.push({
            label: theme.name,
            value: theme.id,
          })

          return theme;
        });

        let firstThemeId = options[0] !== undefined ? options[0].value : false;
        this.setState({
          themesOptions: options,
          themeIdManual: firstThemeId,
          themeIdAutomatic: firstThemeId,
        })
      });
    }

    updateTheme() {
      this.props.setIsThemeInstalling(true);
      this.setState({
          updating: true,
      })
      getApi().installLiquid(this.state.themeIdAutomatic).then(() => {
          this.setState({
              updating: false,
          });
      }).catch(() => {
        this.setState({
          updatedText: 'Some error occurred',
          updating: false,
        })
        setTimeout(() => {
          this.setState({
            updatedText: ''
          })
        }, 5000);
      })
    }

    showErrorManual() {
      this.setState({
        manualUpdatedText: 'Some error occurred. Please use chat to let us know',
      })
      setTimeout(() => {
        this.setState({
          manualUpdatedText: ''
        })
      }, 5000);
    }

    manualUpdateTheme() {
      this.setState({
        manualUpdatedText: "Thanks for request! We wil update your theme and then inform you.",
      });

      getApi().sendThemeInstallRequest({themeId: this.state.themeIdManual}).then(data => {
        if (data.data.hasOwnProperty('errors') && data.data.errors) {
          this.showErrorManual();
        }
      }).catch(() => {
        this.showErrorManual();
      })
    }

    themeChangedAutomatic(value) {
        this.setState({
            themeIdAutomatic: value
        })
    }

    themeChangedManual(value) {
        this.setState({
            themeIdManual: value
        })
    }

    renderThemesSelection() {
        return (
          <div className={formStyles.formGroup}>
            <FormSelect
              label="Choose theme to update"
              name="themeIdAutomatic"
              singleElement={true}
              value={this.state.themeIdAutomatic}
              options={this.state.themesOptions}
              onChange={this.themeChangedAutomatic}
            />
          </div>
        )
    }
    renderManualThemesSelection() {
        return (
          <div className={formStyles.formGroup}>
            <FormSelect
              label="Choose theme to update"
              name="themeIdManual"
              value={this.state.themeIdManual}
              singleElement={true}
              options={this.state.themesOptions}
              onChange={this.themeChangedManual}
            />
          </div>
        )
    }

    renderThemeInstallingMessage() {
      return <div className="noticeBadge noticeInfo">
        Your theme is updating now. You will receive an email as soon as the process is completed.
      </div>
    }

    renderUpdatingWidget() {
      return (
        <div className="pageContent">
          <Container>
            <Row>
                <Col md={8}>
                    <Card
                      autoHeight={true}
                      cardTitle="Greetings, you are one step closer to start selling wholesale!"
                    >
                      <p className="updateNotice">In order to show discounted prices we need to update your current theme.<br/>
                      This can be achieved in two ways:</p>
                      <ol className="updateSteps">
                        <li>
                          <p>Manual theme update by our team. This will take you minimum efforts, you just need to use "Manual theme update" section below and choose the most recent theme within your store.</p>
                          <p>Once you do this - our tech team will be automatically informed and in less than 2 hours we'll get back to you with an update.</p>
                          <p>Moreover, as we recently launched our app - we offer a <b>completely free theme update for the first 1000 merchants</b>.</p>
                        </li>
                        <li>
                          Auto theme update - we recommend using this methods for Shopify pro's and developers. This allows you to choose which Shopify theme shoud be updated, but the process is fully automated and you may need to adjust some coding on your store.
                        </li>
                      </ol>
                    </Card>
                </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card cardTitle="Auto theme update"
                      cardSubtitle="">
                  <ol className="updateSteps">
                    <li>Make a copy of your live theme</li>
                    <li>Choose this theme here in a dropdown and click update.</li>
                    <li>Once the process is completed - make this theme live on your store.</li>
                  </ol>
                  <p className="updateNotice">
                    Please note, theme update make take up to 20 minutes. You will get an email notification
                    once it's ready and it will be also displayed on this page.
                  </p>
                  <div className="textCenter mt3">
                    <div className="mb1">
                      {this.renderThemesSelection()}
                    </div>
                    <div className="mb1">
                      <div className={formStyles.formGroup} >
                        <FormCheckbox
                          label="I have created a theme backup"
                          name="theme-backup"
                          value={this.state.createdBackup}
                          onChange={value => this.setState({createdBackup: value})}
                          singleElement={true}
                        />
                      </div>
                    </div>
                    <div className="mb1">
                      {!this.props.isThemeInstalling && <Button content="Update"
                              disabled={!this.state.createdBackup}
                              onClick={this.manualUpdateTheme}
                              variant="btnPrimary"
                              isLoading={this.state.updating}
                      />}
                    </div>

                    {
                      this.state.updatedText
                        ?
                        <div className="noticeBadge noticeSuccess">
                          {this.state.updatedText}
                        </div>
                        : this.props.isThemeInstalling ?
                          this.renderThemeInstallingMessage()
                          : ''
                    }
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card
                  cardTitle="Free theme update"
                  cardSubtitle=""
                >
                  <ol className="updateSteps">
                    <li>Pick the theme that should be updated from the dropdown.</li>
                    <li>Click UPDATE.</li>
                    <li>Our tech team will take care of it - just relax:)</li>
                    <li>You'll receive an email from us within 2 hours with the results and ready-to-go theme.</li>
                  </ol>
                  <div className="textCenter mt3">
                    <div className="mb1">
                      {this.renderManualThemesSelection()}
                    </div>
                    <div className="mb2">
                      <Button content="Update"
                              disabled={!this.state.themeIdAutomatic}
                              onClick={this.manualUpdateTheme}
                              variant="btnPrimary" />
                    </div>
                    {
                      this.state.manualUpdatedText &&
                      <div className="noticeBadge noticeSuccess">
                        {this.state.manualUpdatedText}
                      </div>

                    }
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    render() {
        return this.renderUpdatingWidget();
    }
}

const mapStateToProps = function (store) {
    return {
      isThemeInstalling: store.appConfig.isThemeInstalling
    };
}

const mapDispatchToProps = {setIsThemeInstalling}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeInstallation);
