import React, {Component} from 'react';
import styles from "./Loader.module.scss";
import PropTypes from "prop-types";

class Loader extends Component {
  render() {
    let loaderClasses = [styles.loaderContainer];

    if (this.props.height) {
      switch (this.props.height) {
        case "sm":
          loaderClasses = [...loaderClasses, styles.sm];
          break
        case "md":
          loaderClasses = [...loaderClasses, styles.md];
          break
        case "xl":
          loaderClasses = [...loaderClasses, styles.xl];
          break
      }
    } else {
      loaderClasses = [...loaderClasses, styles.md]
    }

    return (
      <div className={loaderClasses.join(" ")}>
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    );
  }
}

Loader.propTypes = {
  height: PropTypes.oneOf(['sm', 'md', 'xl']),
}

export default Loader;