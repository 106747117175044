import React, {Component} from 'react';
import {Container, Row, Col} from 'react-grid-system'
import Card from "../Base/Parts/Card";
import InstallationWizard from "../InstalationWizard/InstallationWizard";

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showInstallationWizard: true
    }
  }

  render() {
      return (
          <div className="pageContent">
              <Container>
                <Row>
                  {
                    this.state.showInstallationWizard ? (
                      <Col lg={8}>
                        <Card cardTitle="Welcome to Wholesalify!"
                              cardSubtitle="Follow 3 quick steps to start selling wholesale:">
                          <InstallationWizard />
                        </Card>
                      </Col>
                    ) : ''
                  }
                </Row>
              </Container>
          </div>
      );
  }
}

export default Dashboard;