import {createReducer} from "@reduxjs/toolkit";
import {setIsThemeInstalling, setIsSynchronizing, setAppEnabled} from "../actions/appConfigActions";

const initialState = {
  isSynchronizing: false,
  isThemeInstalling: false,
  appEnabled: false,
};

const appConfigReducer = createReducer(initialState, builder => {
    builder
      .addCase(setIsThemeInstalling, (state, action) => {
          return {...state, isThemeInstalling: action.payload}
      })
      .addCase(setIsSynchronizing, (state, action) => {
        return {...state, isSynchronizing: action.payload}
      })
      .addCase(setAppEnabled(), (state, action) => {
        return {...state, appEnabled: action.payload}
      })
})

export default appConfigReducer