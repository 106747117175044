import React from "react";
import styles from "../../Button/Button.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

class Submit extends React.Component {
  render() {

    let submitClasses = [styles.btn];

    if (this.props.btnVariant) {
      submitClasses = [...submitClasses, styles[this.props.btnVariant]]
    } else {
      submitClasses = [...submitClasses, styles.btnDefault]
    }

    if (this.props.disabled) {
      submitClasses = [...submitClasses, styles.btnDisabled]
    }

    return (
        <button className={submitClasses.join(" ")} type="submit">
          {this.props.submitLabel}
          {this.props.savingProcess ? <FontAwesomeIcon style={{marginLeft: "5px"}} spin={true} icon={faCircleNotch} /> : ''}
        </button>
    );
  }
}

export default Submit;
