import React, {Component} from 'react';
import {Col, Container, Row} from "react-grid-system";
import Modal from "../Base/Modal/Modal";
import CustomerTagForm from "../CustomerTagForm/CustomerTagForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../Base/Button/Button";
import Card from "../Base/Parts/Card";
import getApi from "../../Helpers/Api";
import Table from "../Base/Table/Table";
import styles from "./CustomerTags.module.scss"

class CustomerTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      rowsPerPage: 10,
      progressPending: true,
      showForm: false,
      showDeletePopup: false,
      currentFromKey: false,
      nextFormKey: false,
      curentRowData: false,
      selectedTags: [],
      clearSelectedRowsValue: false,
    }

    this.modalClose = this.modalClose.bind(this);
    this.modalDeleteClose = this.modalDeleteClose.bind(this);
    this.updateData = this.updateData.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.selectEntities = this.selectEntities.bind(this);
    this.deleteTags = this.deleteTags.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    this.setState({
      progressPending: true,
    });

    getApi().getTagsPaginated({perPage: this.state.rowsPerPage, fromKey: this.state.currentFromKey}).then(async response => {
      const newKey = response.data.data.LastEvaluatedKey !== undefined ? response.data.data.LastEvaluatedKey : false;
      this.setState({
        progressPending: false,
        data: await this.processTags(response.data.data.Items),
        nextFormKey: newKey,
      });

    }).catch(() => {
      this.setState({
        progressPending: false,
      })
    })
  }

  processTags(tags) {
    return Promise.all(tags.map((tag) => {
      tag['discountForTable'] = tag['discount'] + (tag['discountType'] === 'fixed' ? '$' : '%');

      return tag;
    }));
  }

  modalClose() {
    this.setState({
      showForm: false,
      curentRowData: {},
    })
  }

  modalDeleteClose() {
    this.setState({
      showDeletePopup: false,
    })
  }

  afterSubmit() {
    this.updateData();
    this.modalClose();
  }

  onPageChange(fromKey) {
    this.setState({
      currentFromKey: fromKey
    }, () => {
      this.updateData();
    })
  }

  onRowsPerPageChange(rowsPerPage) {
    this.setState({
      rowsPerPage: rowsPerPage,
    }, () => {
      this.updateData();
    })
  }

  showEditForm(row) {
    this.setState({
      curentRowData: row,
      showForm: true,
    })
  }

  selectEntities(items) {
    this.setState({selectedTags: items.selectedRows})
  }

  deleteTags() {
    if(this.state.progressPending) {
      return false;
    }

    this.setState({
      progressPending: true,
    })
    getApi().deleteTags(this.state.selectedTags.map(tag => ({SK: tag.SK, tag: tag.tag}))).then(() => {
      this.setState({
        selectedTags: [],
        clearSelectedRowsValue: !this.state.clearSelectedRowsValue,
        showDeletePopup: false,
        progressPending: false,
      });

      this.updateData();
    })
  }

  render() {
    return (
      <div className="pageContent">
        <Container>
          <Row>
            <Col md={12}>
              <Card>
                <div className="tableActions textRight mb2">
                  {this.state.selectedTags.length > 0 && <span className={styles.btnDangerWrapper}><Button
                    content={"Delete tag" + (this.state.selectedTags.length > 1 ? "s" : "")}
                    onClick={() => {this.setState({showDeletePopup: true})}}
                    variant="btnDanger"
                  /></span>}
                  <Button onClick={() => this.setState({showForm: true})}
                          content="Add Customer tag"
                          variant="btnPrimary"
                  />
                </div>
                <Table
                  title="Customer tags"
                  selectableRows={true}
                  selectableRowsHighlight={true}
                  onSelectedRowsChange={this.selectEntities}
                  clearSelectedRows={this.state.clearSelectedRowsValue}
                  progressPending={this.state.progressPending}
                  keyField="SK"
                  striped={true}
                  noHeader={true}
                  sortIcon={<FontAwesomeIcon icon={"sort-up"}/>}
                  paginationServer={true}
                  onChangePage={this.onPageChange}
                  onChangeRowsPerPage={this.onRowsPerPageChange}
                  rowsPerPage={this.state.rowsPerPage}
                  nextFromKey={this.state.nextFormKey}
                  columns={[
                    {
                      name: 'Tag',
                      selector: 'tag',
                      sortable: true,
                    },
                    {
                      name: 'Discount',
                      selector: 'discountForTable',
                      right: true
                    },
                    {
                      cell: row => <Button content="Edit" onClick={() => this.showEditForm(row)} />,
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    }
                  ]}
                  data={this.state.data}/>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          onClose={this.modalClose}
          title="Add Customer tag"
          show={this.state.showForm}
          content={<CustomerTagForm
            afterSubmit={this.afterSubmit}
            curentRowData={this.state.curentRowData}
          />}
        />
        <Modal
          onClose={this.modalDeleteClose}
          title={"Are you sure want to delete a customer tag" + (this.state.selectedTags.length > 1 ? "s" : "") + "?"}
          show={this.state.showDeletePopup}
          additionalClass="modalDelete"
          content={
            <Row>
              <Col md={6}>
                <div className="textCenter">
                  <Button content="Cancel" onClick={()=>{this.setState({showDeletePopup: false})}} />
                </div>
              </Col>
              <Col md={6}>
                <div className="textCenter">
                  <Button content="Delete" isLoading={this.state.progressPending} variant="btnDanger" onClick={this.deleteTags} />
                </div>
              </Col>
            </Row>
          }
        />
      </div>
    );
  }
}

export default CustomerTags;