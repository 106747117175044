import React, {useState, useEffect} from 'react';
import getApi from "../../Helpers/Api";
import Form from "../Base/Form/Form";
import FormInput from "../Base/Form/FormInput/FormInput";
import FormSelect from "../Base/Form/FormSelect/FormSelect";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

let CustomerTagForm = (props) => {
  const [savingProcess, setSavingProcess] = useState(false);

  const validationRules = yup.object().shape({
    tag: yup.string().required("This field is required!").test('len', "Tag name should not have more than 21 symbols", val => val.toString().length <= 21),
    discount: yup.number().required("This field is required!").typeError("You must specify a number").max(100).min(1),
    discountType: yup.object().required("Please specify the discount type")
  })

  const { reset, setError, ...formMethods } = useForm({
    resolver: yupResolver(validationRules),
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (props.curentRowData) {

      if (props.curentRowData.discountType) {
        let discountType = props.curentRowData.discountType;

        props.curentRowData.discountType = {
          label: discountType.charAt(0).toUpperCase() + discountType.slice(1),
          value: discountType,
        }
      }

      reset(props.curentRowData);
    }
  }, [props.curentRowData]);

  const submit = (data) => {
    setSavingProcess(true);

    for (const [key, value] of Object.entries(data)) {
      if (value.hasOwnProperty('value')) {
        data[key] = value.value;
      }
    }

    return getApi().addTag({...data}).then((response) => {
      console.log(response);
      if (response.data.hasOwnProperty('error') && response.data.error) {
        setError('tag', {
          type: "manual",
          message: response.data.data,
        });
      } else {
        afterSubmit();
      }

      setSavingProcess(false);
    });
  }

  const afterSubmit = () => {
    props.afterSubmit && props.afterSubmit();
  }

  return (<Form
    submitLabel={Object.keys(props.curentRowData).length > 0 ? "Save" : "Add"}
    onSubmit={submit}
    formMethods={formMethods}
    savingProcess={savingProcess}
  >
    <FormInput name="tag" type="text" label="Tag name"
    />
    <FormInput name="discount" label="Discount value"
               type="text"
    />
    <FormSelect
      label="Chose discount type"
      name="discountType"
      rules={{required: true}}
      options={[
        {value: 'percent', label: "Percent"},
        {value: 'fixed', label: "Fixed"},
      ]}
    />
  </Form>);
}

export default CustomerTagForm;