import React, {useEffect, useState} from 'react';
import Form from "../Base/Form/Form";
import FormInput from "../Base/Form/FormInput/FormInput";
import EntitiesSelector from "../EntitiesSelector/EntitiesSelector";
import getApi from "../../Helpers/Api";
import FormMultiSelect from "../Base/Form/FormSelect/FormMultiSelect";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const PricingGroupForm = (props) => {
  const [formData, setFormData] = useState({tags: []})
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedCollections, setSelectedCollections] = useState([])
  const [tags, setTags] = useState([])
  const [savingProcess, setSavingProcess] = useState(false)

  yup.addMethod(yup.bool, "checkEntities", function (errorMessage) {
    return this.test(`check-entities`, errorMessage, function (value) {
      const { path, createError } = this;

      let entityType = getValue('selectedEntityType');
      let allEntitiesSelected = getValue('allEntitiesSelected');

      switch (true) {
        case allEntitiesSelected:
          return true;
        case !entityType || (entityType === 'Products' && !selectedProducts):
        case getValue('selectedEntityType') === 'Collections' && !selectedCollections:
          return createError({ path, message: errorMessage })
      }

      return true;
    });

  });
  const validationRules = yup.object().shape({
    pricingGroupName: yup.string().required("This field is required!"),
    tags: yup.array().min(1, "Choose at least one tag").required("Choose at least one tag"),
    entities: yup.bool().checkEntities('Please select entity')
  })

  const { reset, setError, clearErrors, ...formMethods } = useForm({
    resolver: yupResolver(validationRules),
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (props.curentRowData) {
      let currentRowData = props.curentRowData;
      if (typeof currentRowData['tags'] === 'undefined') {
          currentRowData['tags'] = [];
      }

      reset(currentRowData);
      setFormData(currentRowData);

      if (currentRowData.selectedProducts) {
        setSelectedProducts(currentRowData.selectedProducts)
      }

      if (currentRowData.selectedCollections) {
        setSelectedCollections(currentRowData.selectedCollections)
      }
    }
  }, [props.curentRowData]);

  useEffect(() => {
    getApi().getAllTags().then(response => {
      setTags(response.data.data.Items.map(tag => {
        return tag.tag;
      }))
    })
  }, [])


  const setData = (name, value) => {
    let data = formData;

    data[name] = value;

    setFormData(data)
  }

  const handleSubmit = (data) => {
    delete data.allEntitiesSelected
    let allData = {...formData, ...data};

    if (allData.hasOwnProperty("selectedCollections")) {
      delete allData.selectedProducts
    }
    if (allData.hasOwnProperty("selectedCollections")) {
      delete allData.selectedCollections
    }

    if (selectedProducts) {
      allData.selectedProducts = selectedProducts
    }

    if (selectedCollections) {
      allData.selectedCollections = selectedCollections
    }

    setSavingProcess(true);

    return getApi().addPricingGroup(allData).then((response) => {
      if (response.data.hasOwnProperty('error') && response.data.error) {
        setError('pricingGroupName', {
          type: "manual",
          message: response.data.data,
        });
      } else {
        afterSubmit();
      }

      setSavingProcess(false);
    });
  }

  const afterSubmit = () => {
    props.afterSave && props.afterSave();
  }

  const getValue = (name) => {
    return formData[name] ? formData[name] : '';
  }

  return (<>
    <Form
      submitLabel={Object.keys(props.curentRowData).length > 0 ? "Save" : "Add"}
      onSubmit={handleSubmit}
      savingProcess={savingProcess}
      formMethods={formMethods}
    >
      <FormInput name="pricingGroupName" type="text" label="Pricing group name" />
      <FormMultiSelect
        name="tags"
        label="Select customer tags to apply"
        options={tags.map(tag => {
          return {
            label: tag,
            value: tag,
          }
        })}
      />
      <EntitiesSelector
        name="entities"
        clearErrors={clearErrors}
        setPricingGroupProperty={setData}
        getPricingGroupDataByProperty={getValue}
        selectedProducts={selectedProducts}
        selectedCollections={selectedCollections}
        setSelectedProducts={setSelectedProducts}
        setSelectedCollections={setSelectedCollections}
      />
    </Form>
  </>);
}

export default PricingGroupForm;