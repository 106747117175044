import React from "react";
import styles from "../Sidebar.module.scss";
import NavItem from "./NavItem";
import { library } from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

class NavItemGroup extends React.Component{

  constructor(params) {
    super(params);

    this.state = {
      dropdownCollapsed: true,
      active: false,
    };
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      dropdownCollapsed: !this.state.dropdownCollapsed,
      active: !this.state.active,
    })
  }

  render() {
    let itemClasses = [styles.navItem];
    let dropdownClasses = [styles.navItemDropdown];

    if(this.state.active) {
      itemClasses = [...itemClasses, styles.active]
    }

    if(this.state.dropdownCollapsed) {
      dropdownClasses = [...dropdownClasses, styles.collapsed]
    }

    let subNavigation = this.props.item.children;

    return (
      <li className={itemClasses.join(" ")}>
        <a href={this.props.item.url} className={styles.navLink}
           onClick={
             (e) => {
               this.handleClick(e)
             }
           }
        >
          <span className={styles.navIcon}>
            <FontAwesomeIcon icon={['fas', this.props.item.icon]} />
          </span>
          <span className={styles.navText}>{this.props.item.title}</span>
          <span className={styles.dropdownIcon}>
            <FontAwesomeIcon icon={this.state.dropdownCollapsed ? "chevron-down" : "chevron-up"} />
          </span>
        </a>
        <ul className={dropdownClasses.join(" ")}>
          <li className={styles.dropdownTitle}>{this.props.item.title}</li>
          {subNavigation.map((item, key) => {
            return (<NavItem key={key} item={item} showIcon={false} />);
          })}
        </ul>
      </li>
    )
  }
}

export default NavItemGroup;