import styles from "../Form.module.scss";
import PropTypes from "prop-types"
import React from "react";

let FormTextarea = (props) => {
  let {register, value, name, label, singleElement, errors, ...additionalProps} = props

  if (singleElement) {
    return (
      <div className={styles.formGroup}>
        <label className={styles.formLabel} htmlFor={name}>{label}</label>
        <textarea
          name={name}
          value={value}
          className={styles.formControl}
          {...additionalProps}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.formGroup}>
        <label className={styles.formLabel} htmlFor={name}>{label}</label>
        <textarea
          {...register(name)}
          defaultValue={value}
          className={styles.formControl}
          {...additionalProps}
        />
        {
          (errors[name]) && (<span className={styles.formError}>{errors[name].message}</span>)
        }
      </div>
    );
  }
}

FormTextarea.propTypes = {
  register: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
}

export default FormTextarea;