import React from "react";
import styles from "../Form.module.scss";
import PropTypes from "prop-types";

let FormCheckbox = ({name, onChange, singleElement, ...props }) => {

  const handleChange = (event) => {
    onChange(event.target.checked);
  }

  if (singleElement) {
    return (
      <div className={styles.formGroup}>
        <div className={styles.customCheckbox}>
          <label htmlFor={name}>{props.label}</label>
          <input
            type="checkbox"
            name={name}
            id={name}
            checked={props.value}
            onChange={handleChange}
          />

        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.formGroup}>
        <div className={styles.customCheckbox}>
          <label htmlFor={name}>{props.label}</label>
          <input
            type="checkbox"
            name={name}
            id={name}
            checked={props.value}
            onChange={handleChange}
          />

        </div>
        {
          (props.errors[name]) && (<span className={styles.formError}>{props.errors[name].message}</span>)
        }
      </div>
    );
  }
}

FormCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  singleElement: PropTypes.bool
}

export default FormCheckbox;
