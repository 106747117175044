import React from "react";
import styles from "../Sidebar.module.scss";
import NavItem from "../NavItem/NavItem";
import NavItemGroup from "../NavItem/NavItemGroup";

class NavGroup extends React.Component{

  constructor(params) {
    super(params);

    this.state = {};
  }

  render() {
    return (
      <div className={styles.navigationGroup}>
        <div className={styles.navigationGroupTitle}>
          {this.props.navigation.title}
        </div>
        <ul>
          {this.props.navigation.children.map((item, key) => {
            switch (item.type) {
              case 'item':
                return (
                  <NavItem key={key} item={item} showIcon={true}/>
                  );
              case 'item-group':
                return (
                  <NavItemGroup sidebarExpanded={this.props.sidebarExpanded} key={key} item={item}/>
                  );
              default:
                return false;
            }
          })}
        </ul>
      </div>
    )
  }
}

export default NavGroup;