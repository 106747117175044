import Dashboard from "./Components/Pages/Dashboard";
import ThemeInstallation from "./Components/Pages/ThemeInstallation";
import CustomerTags from "./Components/Pages/CustomerTags";
import PricingGroups from "./Components/Pages/PricingGroups";
import Help from "./Components/Pages/Help";
import PricingPlans from "./Components/Pages/PricingPlans";
import Faq from "./Components/Pages/Faq";

let menuItems = {
  items: [
    {
      id: "general",
      title: "General",
      type: "group",
      icon: "",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          icon: "th-list",
          component: <Dashboard />
        },
        {
          id: "customer-tags",
          title: "Customer tags",
          type: "item",
          url: "/customer-tags",
          icon: "tags",
          component: <CustomerTags />
        },
        {
          id: "pricing-groups",
          title: "Pricing groups",
          type: "item",
          url: "/pricing-groups",
          icon: "layer-group",
          component: <PricingGroups />
        },
        {
          id: "installation",
          title: "Theme Update",
          type: "item",
          url: "/theme-installation",
          icon: "download",
          component: <ThemeInstallation />
        },
        {
          id: "pricing",
          title: "Pricing Plans",
          type: "item",
          url: "/pricing-plans",
          icon: "money-bill-wave",
          component: <PricingPlans />
        },
        {
          id: "faq",
          title: "FAQ",
          type: "item",
          url: "/faq",
          icon: "info-circle",
          component: <Faq />
        },
        {
          id: "help",
          title: "Help",
          type: "item",
          url: "/help",
          icon: "question",
          component: <Help />
        },
      ],
    },
  ],
};

export default menuItems
