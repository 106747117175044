import React, {Component} from 'react';
import getApi from "../../Helpers/Api";
import {Row, Col} from 'react-grid-system'
import styles from './InstallationWizard.module.scss';
import buttonStyles from '../Base/Button/Button.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import finishIcon from "../../assets/images/compleated.svg";
import {Link} from "react-router-dom";

class InstallationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTagCompleted: false,
      isPricingGroupCompleted: false,
      isThemeInstallationCompleted: false,
      activeTab: 1
    }
  }

  componentDidMount() {
    this.checkTagCompleted();
    this.checkPricingGroupCompleted();
    this.checkThemeInstallationCompleted();
  }

  checkTagCompleted() {
    getApi().getTagsPaginated().then(response => {
      this.setState({
        isTagCompleted: response.data.data.Items.length > 0
      })
    })
  }

  checkPricingGroupCompleted() {
    getApi().getPricingGroups().then(response => {
      this.setState({
        isPricingGroupCompleted: response.data.data.Items.length > 0
      })
    })
  }

  checkThemeInstallationCompleted() {
    getApi().getFirstTimeInstalledTheme().then(response => {
      this.setState({
        isThemeInstallationCompleted: response.data.data,
      })
    })
  }
  
  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  render() {
    let customerTagClasses = [styles.stepInfo],
        pricingGroupClasses = [styles.stepInfo],
        themeInstallationClasses = [styles.stepInfo],
        finalStepClasses = [styles.finalSteps];

    let customerTagLinkClasses = [],
        pricingGroupLinkClasses = [],
        themeInstallationLinkClasses = [];

    if (this.state.isTagCompleted) {
      customerTagLinkClasses = [...customerTagLinkClasses, styles.stepCompleted]
    }

    if (this.state.isPricingGroupCompleted) {
      pricingGroupLinkClasses = [...pricingGroupLinkClasses, styles.stepCompleted]
    }

    if (this.state.isThemeInstallationCompleted) {
      themeInstallationLinkClasses = [...themeInstallationLinkClasses, styles.stepCompleted]
    }

    if (this.state.isTagCompleted && this.state.isPricingGroupCompleted && this.state.isThemeInstallationCompleted) {
      finalStepClasses = [...finalStepClasses, styles.show]
    }

    switch (this.state.activeTab) {
      case 1:
        customerTagClasses = [...customerTagClasses, styles.active];
        customerTagLinkClasses = [...customerTagLinkClasses, styles.active];
        break;
      case 2:
        pricingGroupClasses = [...pricingGroupClasses, styles.active];
        pricingGroupLinkClasses = [...pricingGroupLinkClasses, styles.active];
        break;
      case 3:
        themeInstallationClasses = [...themeInstallationClasses, styles.active];
        themeInstallationLinkClasses = [...themeInstallationLinkClasses, styles.active];
    }

    return (
      <Row>
        <Col md={12} lg={6}>
          <div className={styles.installationWizardContainer}>
            <ul>
              <li className={customerTagLinkClasses.join(" ")} onClick={() => this.switchTab(1)}>
                <span>
                  {this.state.isTagCompleted ? <FontAwesomeIcon icon={['fas', 'check']} /> : ''}
                </span> Add customer tags
              </li>
              <li className={pricingGroupLinkClasses.join(" ")} onClick={() => this.switchTab(2)}>
                <span>
                  {this.state.isPricingGroupCompleted ? <FontAwesomeIcon icon={['fas', 'check']} /> : ''}
                </span> Create pricing groups
              </li>
              <li className={themeInstallationLinkClasses.join(" ")} onClick={() => this.switchTab(3)}>
                <span>
                  {this.state.isThemeInstallationCompleted ? <FontAwesomeIcon icon={['fas', 'check']} /> : ''}
                </span> Update your theme
              </li>
            </ul>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className={styles.stepsContentWrapper}>
            <div className={customerTagClasses.join(" ")}>
              <h4>Add customer tags</h4>
              <p>This will determine clients who will see discounted pricing</p>
              <Link to="/customer-tags" className={buttonStyles.btn + " " + buttonStyles.btnPrimary}>Add Tag</Link>
            </div>
            <div className={pricingGroupClasses.join(" ")}>
              <h4>Create pricing groups</h4>
              <p>e.g. VIP, Gold, Platinum</p>
              <Link to="/pricing-groups" className={buttonStyles.btn + " " + buttonStyles.btnPrimary}>Create pricing group</Link>
            </div>
            <div className={themeInstallationClasses.join(" ")}>
              <h4>Update your theme</h4>
              <p>Free theme update for first 1000 merchats!</p>
              <Link to="/theme-installation" className={buttonStyles.btn + " " + buttonStyles.btnPrimary}>Update theme</Link>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className={finalStepClasses.join(" ")}>
            <div className={styles.finalStepsIcon}>
              <img src={finishIcon} alt="Completed"/>
            </div>
            <div className={styles.finalStepsContent}>
              <p>
                Once all is set - add tags you've created at step 1 to the customers
              </p>
              <p>
                Login under customer with tag - and check if you see the discounted pricing.
              </p>
              <p>
                Gratz!
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default InstallationWizard;
