import React from "react";
import styles from "../Sidebar.module.scss";
import { library } from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import {NavLink} from "react-router-dom";

library.add(fas);

class NavItem extends React.Component{

  constructor(params) {
    super(params);

    this.state = {};
  }

  render() {
    const activeStyles = {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, .1)'
    }

    let itemClasses = [styles.navItem];

    if(this.props.active) {
      itemClasses = [...itemClasses, styles.active]
    }

    return (
      <li className={itemClasses.join(" ")}>
        <NavLink to={this.props.item.url} className={styles.navLink} activeStyle={activeStyles}>
          {
            this.props.showIcon ? (
            <span className={styles.navIcon}>
              <FontAwesomeIcon icon={['fas', this.props.item.icon]} />
            </span>
            ) : ""
          }

          <span className={styles.navText}>{this.props.item.title}</span>
        </NavLink>
      </li>
    )
  }
}

export default NavItem;