import React, {Component} from 'react';
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

class Button extends Component {

  constructor(props) {
    super(props);

    this.state = {}

    this.makeBtnVariantClass = this.makeBtnVariantClass.bind(this)
  }

  makeBtnVariantClass(variant) {
    return styles['btn' + variant.charAt(0).toUpperCase()];
  }

  render() {
    let buttonClasses = [styles.btn];

    if(this.props.variant) {
      buttonClasses = [...buttonClasses, styles[this.props.variant]]
    } else {
      buttonClasses = [...buttonClasses, styles.btnDefault]
    }

    if(this.props.rounded) {
      buttonClasses = [...buttonClasses, styles.btnRounded]
    }

    if(this.props.disabled) {
      buttonClasses = [...buttonClasses, styles.btnDisabled]
    }

    return (
      <button onClick={this.props.onClick} className={buttonClasses.join(" ")}>
        {this.props.content}
        {this.props.isLoading ? <FontAwesomeIcon style={{marginLeft: "5px"}} spin={true} icon={faCircleNotch} /> : ''}
      </button>
    );

  }
}

Button.propTypes = {
  content: PropTypes.any.isRequired,
  variant: PropTypes.string,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default Button;