import React, {Component} from 'react';
import PropTypes from "prop-types"
import styles from "./Modal.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Modal extends Component {
  render() {
    let modalClasses = [styles.modal];

    if (this.props.additionalClass) {
      modalClasses = [...modalClasses, styles[this.props.additionalClass]];
    }

    return (
      <div className={styles.modalOverlay} hidden={!this.props.show}>
          <div className={modalClasses.join(" ")}>
            <div className={styles.modalHeader}>
              <div onClick={this.props.onClose} className={styles.modalClose}>
                <FontAwesomeIcon icon={['fas', 'times']} />
              </div>
              {
                this.props.title
                  ?
                  <h3>{this.props.title}</h3>
                  :
                  null
              }
            </div>
            <div className={styles.modalContent}>
              {this.props.content}
            </div>
            <div className={styles.modalFooter}>
              {this.props.footerContent}
            </div>
          </div>
      </div>
    );
  }
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    content: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.any
}

export default Modal;