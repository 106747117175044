import React, {Component} from 'react';
import styles from "../../Base/Table/Table.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

class Pagination extends Component {
  render() {
    let prevBtnClass = [styles.btnPagination];
    let nextBtnClass = [styles.btnPagination];

    if (!this.props.prevActive) {
      prevBtnClass = [...prevBtnClass, styles.btnDisabled];
    }
    if (!this.props.nextActive) {
      nextBtnClass = [...nextBtnClass, styles.btnDisabled];
    }

    return (
      <div className={styles.tablePagination} style={{marginTop: 0}}>
        <div className={styles.paginationButtons}>
          <button type="button" className={prevBtnClass.join(" ")}
                  disabled={!this.props.prevActive}
                  onClick={this.props.onPrevPage}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button type="button" className={nextBtnClass.join(" ")}
                  disabled={!this.props.nextActive}
                  onClick={this.props.onNextPage}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    );
  }
}

export default Pagination;