import React from 'react';
import {Row, Col, Container} from "react-grid-system";
import FaqCard from "../Base/Parts/FaqCard";
import {NavLink} from "react-router-dom";
import styles from "../Sidebar/Sidebar.module.scss";

let Faq = () => {
  const faqItems = [
    {
      title: 'How to setup your app?',
      content:
        <div>
          <p>There are a few easy steps you should follow:</p>
          <ol>
            <li>
              Request a
              <NavLink to='/theme-installation' className={styles.navLink} >
                <span className={styles.navText}>&nbsp;theme update</span>
              </NavLink> from our team or make an automatic install.<br />
              Each theme is unique and our team will make sure it is compatible with all your apps and configs.
            </li>
            <li>
              <NavLink to='/customer-tags' className={styles.navLink} >
                <span className={styles.navText}>Create </span>
              </NavLink> a customer TAG(e.g. VIP, SILVER, GOLD)<br />
              Using those tags you'll define which customers should get a discount.
            </li>
            <li>
              <NavLink to='/pricing-groups' className={styles.navLink} >
                <span className={styles.navText}>Create </span>
              </NavLink> a PRICING GROUP<br />
              Here you need to choose the customer tag it will be related to, choose collections/products that should be
              discounted and set a discount value(fixed or percentage)
            </li>
            <li>
              Manually<a target="_blank" href="https://help.shopify.com/en/manual/shopify-admin/productivity-tools/using-tags#add-a-tag">
                &nbsp;tag
              </a> the clients in Shopify admin that you created on a step#2
            </li>
            <li>
              That's it! Now you can test how it works:
              <p> - create a test customer</p>
              <p> - tag it</p>
              <p> - login under this customer into your store and check the discounted pricing</p>
            </li>
          </ol>
        </div>
    },
    {
      title: 'What is the difference between Automated and Manual theme update?',
      content:
        <div>
          <p>
            We are striving to support all Shopify themes, but we can't predict the combination of different
            apps/settings for each specific store.
          </p>
          <p>
            So the main difference is that automated theme update works for basic stores without too many customizations
            on them.
          </p>
          <p>
            While the Manual theme update will be done by our team, we will check each specific app and configuration to
            make sure it doesn't have any conflicts. Feel free to choose this option when you're not confident how to
            setup an app.
          </p>
        </div>
    },
    {
      title: 'I want my customers to see specific discount for different collections, how can I do it?',
      content:
        <div>
          <p>
            This is possible to achieve with our app. All you need to do is to
            <NavLink to='/pricing-groups' className={styles.navLink} >
              <span className={styles.navText}>&nbsp;create</span>
            </NavLink> different pricing groups for the
            same customer tag.
          </p>
          <p>For example:</p>
          <p>You have collection A with 10% discount</p>
          <p>You have collection B with 20% discount</p>
          <p>You have collection C with 30% discount</p>
          <br/>
          <p>To achieve that you need to create 3 different pricing groups for the same customer tag.</p>
        </div>
    },
    {
      title: 'I followed all steps, but the pricing doesn\'t change. What should I do?',
      content:
        <div>
          <p>
            Firstly, you need to make sure that customer accounts are enabled in your store. Please refer to this
            <a target="_blank" href="https://help.shopify.com/en/manual/checkout-settings/customer-accounts">
              &nbsp;article
            </a>
          </p>
          <p>
            Second - create a test customer and login under his credentials in incognito mode.
          </p>
          <p>
            Third - please make sure you don't have any other wholesale apps enabled at the same time.
          </p>
          <p>
            If that doesn't help - please let us know by submitting a short form
            <NavLink to='/help' className={styles.navLink} >
              <span className={styles.navText}>&nbsp;here</span>
            </NavLink>
          </p>
        </div>
    }
  ];

  return (
    <Container>
      <Row>
        <Col lg={12} sm={6}>
          {
            faqItems.map((item, key) => {
              return <FaqCard title={item.title} content={item.content} key={key}/>
            })
          }
        </Col>
      </Row>
    </Container>
  );
}

export default Faq;
